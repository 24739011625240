import { FEATURE_FLAGS } from '../../../../featureFlags';
import { frontendURL } from '../../../../helper/URLHelper';

const CLINICDESK_ACCOUNT_ID =
  parseInt(import.meta.env.VITE_CLINICDESK_ACCOUNT_ID, 10) ?? 49;

const primaryMenuItems = accountId => [
  {
    icon: 'mail-inbox',
    key: 'inboxView',
    label: 'INBOX_VIEW',
    featureFlag: FEATURE_FLAGS.INBOX_VIEW,
    toState: frontendURL(`accounts/${accountId}/inbox-view`),
    toStateName: 'inbox_view',
  },
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
  },
  {
    icon: 'captain',
    key: 'captain',
    label: 'CAPTAIN',
    featureFlag: FEATURE_FLAGS.CAPTAIN,
    toState: frontendURL(`accounts/${accountId}/captain`),
    toStateName: 'captain',
  },
  {
    icon: 'book-contacts',
    key: 'contacts',
    label: 'CONTACTS',
    featureFlag: FEATURE_FLAGS.CRM,
    toState: frontendURL(`accounts/${accountId}/contacts`),
    toStateName: 'contacts_dashboard',
  },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'REPORTS',
    featureFlag: FEATURE_FLAGS.REPORTS,
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'account_overview_reports',
  },
  {
    icon: 'megaphone',
    key: 'campaigns',
    label: 'CAMPAIGNS',
    featureFlag: FEATURE_FLAGS.CAMPAIGNS,
    toState: frontendURL(`accounts/${accountId}/campaigns`),
    toStateName: 'ongoing_campaigns',
  },
  {
    ...(parseInt(accountId, 10) === CLINICDESK_ACCOUNT_ID
      ? {
          icon: 'library',
          key: 'helpcenter',
          label: 'HELP_CENTER.TITLE',
          featureFlag: FEATURE_FLAGS.HELP_CENTER,
          alwaysVisibleOnClinicdeskInstances: true,
          toState: frontendURL(`accounts/${accountId}/portals`),
          toStateName: 'default_portal_articles',
        }
      : null),
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
  },
  {
    icon: 'calendar-3-day',
    key: 'crm',
    label: 'CRM',
    toState: frontendURL(`accounts/${accountId}/crm/pipeline`),
    toStateName: 'crm_panel',
  },
];

export default primaryMenuItems;
